import React, { useEffect, useState } from "react";
import { METHODS, request } from "../constants/Client";
import "./css/VideoList.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import defaultImage from "../images/artworkEmptyImage.png";
import { LinearProgress } from "@mui/material";
import { baseURL } from "../constants/Config";

const VideoList = () => {
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    request({
      method: METHODS.GET,
      url: "/api/video/",
    }).then((videores) => {
      if (videores?.data?.data?.length > 0) {
        setVideoList(
          videores.data.data.sort(function (a, b) {
            return a.order - b.order;
          })
        );
      }
      setIsLoading(false);
    });
  }, []);
  console.log("videolist:", videoList);

  const formateOrder = (order) => {
    return ("0" + order).slice(-2);
  };

  const onLoadedData = (e) => {
    e.target.style["background-image"] = null;
  };

  return (
    <>
      {isLoading ? (
        <div className="videolist-container">
          <div>
            <h4 style={{ color: "#fff" }}>FULLBLACK</h4>
            <LinearProgress className="loader-homepage" />
          </div>
        </div>
      ) : (
        <VideoPage>
          <div className="container video-column-list">
            <div className="top-arrow">SELECT A VIDEO</div>
            <StyleVideoGroup className="video-list-main">
              {videoList?.length > 0 &&
                videoList?.map((videolist, index) => {
                  const link = videolist?.title
                    ? `/showreel/${videolist?.title.split(" ").join("-")}`
                    : `/showreel/${videolist.id}`;
                  return (
                    <VideoListContent
                      key={videolist?.video}
                      disableRemotePlayback
                    >
                      <div className="video-box-main">
                        <Link
                          className="link-box"
                          to={link}
                          state={{
                            title: videolist.title,
                            subtitle: videolist.subtitle,
                            id: videolist?.id,
                          }}
                        >
                          <div className="video-box cssanimation fadeInBottom">
                            <div className="video-img cssanimation fadeInBottom">
                              <video
                                playsInline
                                onLoad={onLoadedData}
                                style={{
                                  backgroundImage: `url(${defaultImage})`,
                                  backgroundSize: "100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                                preload="metadata"
                                src={`${baseURL}media/${videolist?.video}#t=0.001`}
                                width={"100%"}
                                height={200}
                              ></video>
                            </div>
                            <div className="video-content cssanimation fadeInBottom">
                              <h3>{formateOrder(index + 1)}</h3>
                              <h4>{videolist?.title}</h4>
                              <p>{videolist?.subtitle?.toUpperCase()}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </VideoListContent>
                  );
                })}
            </StyleVideoGroup>
            <div className="bottom-close">
              <CloseRedirect to="/" className="linkEffectClose linkEffect">
                CLOSE
              </CloseRedirect>
            </div>
          </div>
        </VideoPage>
      )}
    </>
  );
};

export default VideoList;

const VideoPage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const StyleVideoGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px 0;
`;

const VideoListContent = styled.div``;

export const CloseRedirect = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: #000;
  position: relative;
`;
