import { Button, Input } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CloseRedirect,
  ImgContainer,
  PageContainer,
  PageContent,
  StyledImg,
} from "./AboutUs";
import styled from "styled-components";
import "./css/ContactUs.css";
import { METHODS, request } from "../constants/Client";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../constants/Config";

const ariaLabel = { "aria-label": "description" };

const ContactUs = () => {
  const navigate = useNavigate();
  const [contactView, setContactView] = useState();
  const [socialIcons, setSocialIcons] = useState();
  const [form, setForm] = useState({
    Name: "",
    Email: "",
    Subject: "",
    Message: "",
  });
  const [message, setMessage] = useState(false);
  const [animation, setAnimation] = useState(false);
  useEffect(() => {
    request({
      method: METHODS.GET,
      url: "/api/page/contact/",
    }).then((r) => setContactView(r.data.data));

    request({
      method: METHODS.GET,
      url: "/api/link/",
    }).then((res) => setSocialIcons(res.data.data));
  }, []);
  // console.log("request:", contactView);

  // console.log("request social:", socialIcons);

  const onFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onFormSubmit = (e) => {
    try {
      e.preventDefault();
      request({
        method: METHODS.POST,
        url: "/api/contact/",
      }).then((resform) => {
        // console.log("resp", resform);
        setForm(resform.data);
        if (resform.status === 200) setMessage(true);
      });
    } catch (error) {
      alert("error");
    }
  };
  // console.log("requestContact:", form);
  const closePage = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      navigate("/");
    }, 500);
  };
  return (
    <ContactPageList className="contact-main">
      <PageContent>
        <PageContainer>
          <div>
            <ImgContainer>
              <StyledImg
                src={`${baseURL}media/${contactView?.image}`}
                className={
                  animation
                    ? `cssanimation-2 fadeInBottom-2`
                    : `cssanimation fadeInBottom`
                }
              />
            </ImgContainer>
            <div className="title">
              <h1 className="cssanimation fadeInBottom">
                {contactView?.title}
              </h1>
              <div
                dangerouslySetInnerHTML={{ __html: contactView?.content }}
                className="cssanimation fadeInBottom contact-heading "
              ></div>
            </div>
            <div>
              <SocialList className="cssanimation fadeInBottom">
                {contactView?.link
                  ?.filter((social) => social.type === "socialmedia")
                  ?.map((social) => {
                    return (
                      <SocialIconsList
                        key={social?.link}
                        className="social-icons"
                      >
                        <a
                          href={social?.link}
                          rel="noreferrer"
                          target="_blank"
                          animation="fadeIn"
                        >
                          <img
                            src={`${baseURL}media/${social?.image}`}
                            alt="social icons"
                            className="social-img"
                          />
                          <img
                            src={`${baseURL}media/${social?.image}`}
                            alt="social icons"
                            className="social-img"
                          />
                        </a>
                      </SocialIconsList>
                    );
                  })}
              </SocialList>
            </div>
            <FormConetnt onSubmit={onFormSubmit}>
              <ContactForm className="contact-form">
                <TextFieldConatct
                  required
                  placeholder="NAME"
                  type="text"
                  inputProps={ariaLabel}
                  name="Name"
                  value={form.Name}
                  onChange={onFormChange}
                  autoComplete="off"
                />
                <TextFieldConatct
                  required
                  placeholder="EMAIL"
                  type="email"
                  inputProps={ariaLabel}
                  name="Email"
                  value={form.Email}
                  onChange={onFormChange}
                  autoComplete="off"
                />
                <TextFieldConatct
                  required
                  placeholder="SUBJECT"
                  type="text"
                  inputProps={ariaLabel}
                  name="Subject"
                  value={form.Subject}
                  onChange={onFormChange}
                  autoComplete="off"
                />
                <TextAreaContact
                  required
                  placeholder="MESSAGE"
                  type="text"
                  inputProps={ariaLabel}
                  rows="6"
                  name="Message"
                  value={form.Message}
                  onChange={onFormChange}
                  autoComplete="off"
                />
                <SendPosition>
                  <SendBtn variant="text" type="submit">
                    <span className="submit-button">SEND</span>
                  </SendBtn>
                </SendPosition>
                {message && (
                  <MessageGreet>
                    Thanks for your enquiry, we'll be in touch shortly.
                  </MessageGreet>
                )}
              </ContactForm>
            </FormConetnt>
          </div>
          <div className="bottom-close">
            <CloseRedirect
              className="linkEffectClose linkEffect"
              onClick={closePage}
            >
              Close
            </CloseRedirect>
          </div>
        </PageContainer>
      </PageContent>
    </ContactPageList>
  );
};

export default ContactUs;

const ContactPageList = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialList = styled.ul`
  display: inline-flex;
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-inline-start: 0px;
`;

const SocialIconsList = styled.li`
  margin: 0 10px;
  font-size: 18px;
  line-height: 18px;
  overflow: hidden;
  position: relative;
`;

const FormConetnt = styled.form`
  position: relative;
  overflow: hidden;
`;

const ContactForm = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const TextFieldConatct = styled(Input)`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 25px;
  outline: none;
`;

const TextAreaContact = styled.textarea`
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #949494;
  outline: none;
  font-size: x-small;
  font-family: inherit;
`;

const SendPosition = styled.div`
  display: flex;
  justify-content: end;
`;

const SendBtn = styled(Button)`
  font-size: 11px;
  font-weight: 700;
  width: 20px;
`;

const MessageGreet = styled.div`
  font-size: 15px;
  font-weight: lighter;
`;
