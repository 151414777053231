import React from "react";
import "../component/css/LandingPage.css";
import pencilpic from "../images/pencil.jpg";
import { Box } from "@mui/system";
import styled from "styled-components";
import { StyledTitle } from "./LandingVideoPage";

const LandingPage = () => {
  return (
    <>
      <Box>
        <div>
          <StyledImg src={pencilpic} alt="Logo"></StyledImg>
          <StyledTitle>Full Black</StyledTitle>
        </div>
      </Box>
    </>
  );
};

export default LandingPage;

const StyledImg = styled.img`
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  height: 100vh;
  width: 100vw;
`;
