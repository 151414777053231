import "./App.css";
import NavigationRoute from "./component/navigation/NavigationRoute";

function App() {
  return (
    <>
      <NavigationRoute />
    </>
  );
}

export default App;
