import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { METHODS, request } from "../constants/Client";
import "./css/AboutUs.css";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../constants/Config";

const AboutUs = () => {
  const navigate = useNavigate();
  const [about, setAbout] = useState();
  const [socialicon, setSocialicons] = useState();
  const [animation, setAnimation] = useState(false);
  // const [linkContent, setLinkContent] = useState();
  const [typeTitle, setTypeTitle] = useState();

  useEffect(() => {
    request({
      method: METHODS.GET,
      url: "/api/page/about/",
    }).then((r) => setAbout(r.data.data));

    request({
      method: METHODS.GET,
      url: "/api/link/",
    }).then((res) => setSocialicons(res.data.data));

    // request({
    //   method: METHODS.GET,
    //   url: "/api/page/",
    // }).then((res) => setLinkContent(res.data.data));

    request({
      method: METHODS.GET,
      url: "/api/linktype/",
    }).then((restitle) => setTypeTitle(restitle.data.data));
  }, []);
  // console.log("restitle", typeTitle);
  // console.log("pagedata", about);

  const closePage = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      navigate("/");
    }, 500);
  };
  // console.log("request", socialicon);

  return (
    <>
      <PageContent className="about-main">
        <PageContainer>
          <ImgContainer>
            <StyledImg
              src={`${baseURL}media/${about?.image}`}
              className={
                animation
                  ? `cssanimation-2 fadeInBottom-2`
                  : `cssanimation fadeInBottom`
              }
            />
          </ImgContainer>

          <div className="title">
            <h1 className="cssanimation fadeInBottom">{about?.title}</h1>
          </div>
          <div className="subTitle">{about?.subtitle}</div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: about?.content }}
              className="cssanimation fadeInBottom aboutContent"
            />

            <LogoGrid className="bottom-logo">
              <div className="logo-left">
                {typeTitle
                  ?.filter(
                    (socialvideogame) => socialvideogame.name === "videogame"
                  )
                  .map((socialvideotab) => {
                    return (
                      <h4 className="cssanimation fadeInBottom">
                        {socialvideotab.title}
                      </h4>
                    );
                  })}
                {/* <h4 className="cssanimation fadeInBottom">
                OUR VIDEO GAMES PRODUCTION
              </h4>*/}

                <LogoList>
                  {about?.link
                    ?.filter((socialvideo) => socialvideo.type === "videogame")
                    ?.map((socialvideo) => {
                      return (
                        <GameIcon
                          key={socialvideo?.link}
                          className="cssanimation fadeInBottom"
                        >
                          <a
                            href={socialvideo?.link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img
                              src={`${baseURL}media/${socialvideo?.image}`}
                              alt="social icons"
                              className="about-img"
                            />
                          </a>
                        </GameIcon>
                      );
                    })}
                </LogoList>
              </div>
              <div className="logo-right">
                {typeTitle
                  ?.filter(
                    (socialvideogame) => socialvideogame.name === "metaverse"
                  )
                  .map((socialvideotab) => {
                    return (
                      <h4 className="cssanimation fadeInBottom">
                        {socialvideotab.title}
                      </h4>
                    );
                  })}
                {/*<h4 className="cssanimation fadeInBottom">
               PARTNERS FOR METAVERSE & DIGITAL
             </h4>*/}
                <LogoList>
                  {about?.link
                    ?.filter((socialvideo) => socialvideo.type === "metaverse")
                    ?.map((socialvideo) => {
                      return (
                        <GameIcon
                          key={socialvideo?.link}
                          className="cssanimation fadeInBottom"
                        >
                          <a
                            href={socialvideo?.link}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <img
                              src={`${baseURL}media/${socialvideo?.image}`}
                              alt="social icons"
                              className="about-img"
                            />
                          </a>
                        </GameIcon>
                      );
                    })}
                </LogoList>
              </div>
            </LogoGrid>

            <div className="bottom-close">
              <CloseRedirect
                to="#"
                className="linkEffectClose linkEffect"
                onClick={closePage}
              >
                Close
              </CloseRedirect>
            </div>
          </div>
        </PageContainer>
      </PageContent>
    </>
  );
};

export default AboutUs;

export const PageContent = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0 30px;
  height: 100vh;
  box-sizing: border-box;
`;

export const PageContainer = styled.div`
  text-align: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  a {
    text-decoration: none;
  }
`;

export const ImgContainer = styled.div`
  overflow: hidden;
  height: 70px;
  margin-bottom: 20px;
`;

export const StyledImg = styled.img`
  width: 70px;
  margin-left: auto;
  margin-right: auto;
`;

const LogoGrid = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const LogoList = styled.ul`
  display: flex;
  justify-content: space-around;
  padding: 0;
  align-items: center;
`;

const GameIcon = styled.li`
  margin: 0 10px;
  font-size: 18px;
  line-height: 18px;
  overflow: hidden;
  position: relative;
`;

export const CloseRedirect = styled.div`
  display: inline-block;
  text-decoration: none;
  color: #000;
  position: relative;
  cursor: pointer;
`;
