import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationProvider from "./NavigationProvider";

const NavigationRoute = () => {
  return (
    <>
      <Router>
        <Routes>
          {NavigationProvider.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Routes>
      </Router>
    </>
  );
};

export default NavigationRoute;
