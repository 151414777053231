import React from "react";
import styled from "styled-components";
import { StyledVideo } from "./Home";

const LandingVideoPage = () => {
  return (
    <div>
      <StyledVideo
        disableRemotePlayback
        playsInline
        muted
        autoPlay
        loop
        duration
        width={window.screen.availWidth}
        height={window.innerHeight}
      >
        <source
          src="https://full-black.s3.amazonaws.com/videos/test_FB_1.mp4"
          type="video/mp4"
        ></source>
      </StyledVideo>
      <StyledTitle>FULLBLACK</StyledTitle>
    </div>
  );
};

export default LandingVideoPage;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -35%;
  margin-bottom: -30%;
  font-size: 200px;
  font-weight: 700;
  mix-blend-mode: exclusion;
  color: #fff;
  overflow: hidden;
  font-family: "polysansbulky_wide";
`;
