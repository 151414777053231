import axios from "axios";
import { baseURL } from "./Config";

export const METHODS = {
  GET: "get",
  DELETE: "delete",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
};

const axiosConfig = {
  baseURL,
};

function createAxiosInstance() {
  return axios.create(axiosConfig);
}

export const request = createAxiosInstance();

// console.log(request);

// const Client = {

// };

// export default Client;
