import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick/lib/slider";
import animation1 from "../sound/animation_1.mp3";
import animation2 from "../sound/animation_2.mp3";
import { METHODS, request } from "../constants/Client";
import { Link, useNavigate } from "react-router-dom";

//css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/Home.css";
import styled from "styled-components";

//material-ui
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
} from "@mui/material";
import { baseURL } from "../constants/Config";

const Home = () => {
  const navigate = useNavigate();
  const videoRef = useRef();
  const [carsouselVideo, setCarsouselVideo] = useState();
  const [progress, setProgress] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [muted, setMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [logoShow, setLogoShow] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(true);
  const [sliderRef, setSliderRef] = useState(null);
  const [isFade, setIsFade] = useState(false);

  const audio1 = new Audio(animation1);
  const audio2 = new Audio(animation2);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setLogoShow(false);
      }, 500);
    }
  }, [isLoading]);

  useEffect(() => {
    // homeDataList();
    setIsLoading(true);
    request({
      method: METHODS.GET,
      url: "/api/video/",
      responseType: "stream",
    })
      .then((res) => {
        if (res?.data?.data?.length > 0) {
          setCarsouselVideo(
            res.data.data.sort(function (a, b) {
              return a.order - b.order;
            })
          );
        }
        setIsLoading(false);
      })
      .catch((e) => {
        alert(e.message);
      });
  }, []);

  useEffect(() => {
    videoRef?.current?.play();
  }, []);

  // console.log("request:", carsouselVideo);

  // const hoverEvent1 = () => {
  //   audio1.play();
  // };

  // const hoverEvent2 = () => {
  //   audio2.play();
  // };

  const SamplePrevArrow = (props) => {
    const { className, onClick, currentSlide } = props;
    return (
      <div>
        <StyledArrowBack
          //onMouseOver={hoverEvent2}
          height="50"
          width="50"
          className={className}
          onClick={() => {
            setCurrentSlide(currentSlide);
            setIsFade(false);
            const a = document.getElementById(currentSlide);
            a.pause();
            if (currentSlide === 0) {
              const lastIndex = carsouselVideo.length - 1;
              const b = document.getElementById(lastIndex);
              b.currentTime = 0;
              b.play();
            } else {
              const b = document.getElementById(currentSlide - 1);
              b.currentTime = 0;
              b.play();
            }
            onClick();
            setIsVideoLoaded(true);
          }}
          // onChange={MutedOnArrowClick}
        />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick, currentSlide } = props;
    return (
      <div>
        <StyledArrowForward
          //onMouseOver={hoverEvent2}
          height="50"
          width="50"
          className={className}
          onClick={() => {
            setCurrentSlide(currentSlide);
            setIsFade(false);
            const a = document.getElementById(currentSlide);
            a.pause();
            if (currentSlide === carsouselVideo.length - 1) {
              const b = document.getElementById(0);
              b.currentTime = 0;
              b.play();
            } else {
              const b = document.getElementById(currentSlide + 1);
              b.currentTime = 0;
              b.play();
            }
            onClick();
            setIsVideoLoaded(true);
          }}
        />
      </div>
    );
  };

  // const MutedOnFwdArrowClick = () => {
  //   setMuted((prev) => prev);
  // };

  // const MutedOnArrowClick = () => {
  //   alert("call");
  //   setMuted(true);
  // };

  const settings = {
    // infinite: true,
    // autoplay: true,
    // speed: 1000,
    // infinite: true,
    // speed: 500,
    lazyLoad: "progressive",
    //fade: isFade,
    // lazyLoad: "anticipated",
    // slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  // const handlePlayVideo = () => videoRef.current.play();

  const handleProgress = (e) => {
    if (isNaN(e.target.duration)) return;
    setProgress((e.target.currentTime / e.target.duration) * 100);
  };

  const MutedOnClick = () => {
    setMuted(!muted);
  };

  const closePage = (path) => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      navigate(path);
    }, 500);
  };

  const onLoadedData = () => {
    setIsVideoLoaded(false);
  };

  const onEndedVideo = (e, index) => {
    const a = document.getElementById(index);
    a.pause();
    if (index === carsouselVideo.length - 1) {
      const b = document.getElementById(0);
      b.currentTime = 0;
      b.play();
    } else {
      const b = document.getElementById(index + 1);
      b.currentTime = 0;
      b.play();
    }
    setIsFade(true);
    sliderRef?.slickNext();
  };

  return (
    <div>
      <Stack sx={{ color: "primary" }}>
        {isLoading ? (
          <div className="home-loader-container">
            {/* <div className={ isLoading ? `home-loader-box` : `home-loader-box home-loader-box-d`}> */}
            <div className="home-loader-box">
              {logoShow && <h3 style={{ color: "#fff" }}>FULLBLACK</h3>}
              {isLoading && <LinearProgress className="loader-homepage" />}
            </div>
          </div>
        ) : (
          <div className="home-layout">
            {/* {isVideoLoaded && (
              <CircularProgress
                style={{
                  color: "white",
                  width: 40,
                  height: 40,
                  position: "absolute",
                  margin: "0 auto",
                  left: 0,
                  right: 0,
                  zIndex: 9,
                  top: "50%",
                }}
              />
            )} */}

            <StyledSlider ref={setSliderRef} autoPlay {...settings}>
              {carsouselVideo?.length > 0 &&
                carsouselVideo?.map((resdata, index) => {
                  return (
                    <div key={resdata?.id}>
                      {/* {index === currentSlide && ( */}
                      <StyledVideo
                        id={index}
                        disableRemotePlayback
                        playsInline
                        fade={true}
                        muted={muted}
                        preload="metadata"
                        onEnded={(e) => onEndedVideo(e, index)}
                        autoPlay={true}
                        duration
                        onProgress={handleProgress}
                        width={window.screen.availWidth}
                        height={window.innerHeight}
                        ref={videoRef}
                        onLoadedData={onLoadedData}
                      >
                        <source
                          src={`${baseURL}media/${resdata?.video}#t=0.001`}
                          type="video/mp4"
                        />
                      </StyledVideo>
                      {/* )}
                      {/* <div className="slider-text-main">
                              <div className="slider-title">{resdata?.title}</div>
                              <div className="slider-sub-title">
                                {resdata?.subtitle}
                              </div>
                            </div> */}
                    </div>
                  );
                })}
            </StyledSlider>

            <div className="center-logo">
              <div className="slider-title-main">
                <NavHeadding to="/" className="slider-title">
                  <div className={animation && `cssanimation-2 fadeInBottom-2`}>
                    <h3>
                      FullBlack
                      {/* <div className="block">
                        <span className="letter">F</span>
                        <span className="letter">u</span>
                        <span className="letter">l</span>
                        <span className="letter">l</span>
                        <span className="letter">B</span>
                        <span className="letter">l</span>
                        <span className="letter">a</span>
                        <span className="letter">c</span>
                        <span className="letter">k</span>
                      </div>
                      <div className="block">
                        <span className="letter">F</span>
                        <span className="letter">u</span>
                        <span className="letter">l</span>
                        <span className="letter">l</span>
                        <span className="letter">B</span>
                        <span className="letter">l</span>
                        <span className="letter">a</span>
                        <span className="letter">c</span>
                        <span className="letter">k</span>
                      </div> */}
                    </h3>
                    <div className="digital-agency">Digital agency</div>
                  </div>
                </NavHeadding>
              </div>
            </div>
            <div className="navbar-main">
              <NavBar>
                <div className="about-text">
                  <NavLink
                    className="linkEffect"
                    onClick={() => closePage("/aboutus")}
                  >
                    <div
                      className={animation && `cssanimation-2 fadeInBottom-2`}
                    >
                      {" "}
                      {/*onMouseOver={hoverEvent1}*/}
                      ABOUT
                    </div>
                  </NavLink>
                </div>
                {/* <div className="slider-title-main">
                  <NavHeadding to="/" className="slider-title">
                    <div
                      className={animation && `cssanimation-2 fadeInBottom-2`}
                    >
                      <h3>
                        <div className="block">
                          <span className="letter">F</span>
                          <span className="letter">U</span>
                          <span className="letter">L</span>
                          <span className="letter">L</span>
                          <span className="letter">B</span>
                          <span className="letter">L</span>
                          <span className="letter">A</span>
                          <span className="letter">C</span>
                          <span className="letter">K</span>
                        </div>
                        <div className="block">
                          <span className="letter">F</span>
                          <span className="letter">U</span>
                          <span className="letter">L</span>
                          <span className="letter">L</span>
                          <span className="letter">B</span>
                          <span className="letter">L</span>
                          <span className="letter">A</span>
                          <span className="letter">C</span>
                          <span className="letter">K</span>
                        </div>
                      </h3>
                    </div>
                  </NavHeadding>
                </div> */}
                <div className="contact-text">
                  <NavLink
                    className="linkEffect"
                    onClick={() => closePage("/contactus")}
                  >
                    <div
                      className={animation && `cssanimation-2 fadeInBottom-2`}
                    >
                      {" "}
                      {/*onMouseOver={hoverEvent1}*/}
                      CONTACT
                    </div>
                  </NavLink>
                </div>
              </NavBar>
            </div>
            <div className="footer">
              <FooterLink to="/videolist" className="linkEffect">
                <div className={animation && `cssanimation-2 fadeInBottom-2`}>
                  {" "}
                  {/*onMouseOver={hoverEvent1}*/}
                  ALL VIDEOS
                </div>
              </FooterLink>

              <Box sx={{ width: "290px" }} className="footer-center">
                {/*<LinearProgress
                  variant="determinate"
                  id="progress"
                  max="100"
                  value={progress}
                  width="50%"
                /> */}
              </Box>
              <div>
                <Button variant="text" onClick={MutedOnClick}>
                  <div>
                    SOUND
                    <SoundBtn>{muted ? " OFF" : " ON"}</SoundBtn>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default Home;

const NavBar = styled.div`
  display: flex;
  position: absolute;
  z-index: 999;
  color: #fff;
  top: 5%;
  justify-content: space-between;
  width: 93%;
  left: 0;
  right: 0;
  margin: 0 auto;
  mix-blend-mode: exclusion;
`;

const NavLink = styled.div`
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

const NavHeadding = styled(Link)`
  font-size: 20px;
  text-decoration: none;
  color: #fff;
`;

export const StyledVideo = styled.video`
  object-fit: cover;
  max-height: 100%;
  width: 100%;
  & video::-internal-media-controls-overlay-cast-button {
    display: none;
  }
`;

const StyledSlider = styled(Slider)`
  overflow: hidden;
  .slick-track {
    height: 100vh;
    overflow: hidden;
    // transform: scale(0.5);
    // transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .slick-initialized .slick-slide {
    height: 100%;
    // transform: scale(0.5);
    // transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const StyledArrowForward = styled(ArrowForwardIosIcon)`
  // display: block;
  // margin-right: 5%;
  right: 50px;
  z-index: 9;
  font-size: 1.5rem;
  color: #fff;
  border: 1px solid #808080c2;
  border-radius: 50%;
  padding: 12px;
  font-size: 14px !important;

  &:hover {
    // display: block;
    // margin-right: 5%;
    right: 50px;
    z-index: 9;
    font-size: 1.5rem;
    color: #fff;
    border: 1px solid #808080c2;
    border-radius: 50%;
    padding: 12px;
    font-size: 14px !important;
  }
`;

const StyledArrowBack = styled(ArrowBackIosIcon)`
  display: block;
  // margin-left: 5%;
  left: 50px;
  z-index: 9;
  font-size: 1.5rem;
  color: #fff;
  border: 1px solid #808080c2;
  border-radius: 50%;
  padding: 12px 9px 12px 15px;
  font-size: 14px !important;
  &:hover {
    // display: block;
    left: 50px;
    // margin-right: 5%;
    z-index: 9;
    font-size: 1.5rem;
    color: #fff;
    border: 1px solid #808080c2;
    border-radius: 50%;
    padding: 12px 9px 12px 15px;
    font-size: 14px !important;
  }
`;

// const Footer = styled.div`
//   display: flex;
//   position: absolute;
//   z-index: 999;
//   color: #fff;
//   bottom: 5%;
//   justify-content: space-between;
//   width: 93%;
//   left: 0;
//   right: 0;
//   margin: 0 auto;
// `;

const FooterLink = styled(Link)`
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  position: relative;
  mix-blend-mode: exclusion;
`;

const SoundBtn = styled.span`
  color: #ffb400;
`;
