import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import animation1 from "../sound/animation_1.mp3";
import { METHODS, request } from "../constants/Client";
import { LinearProgress, Stack } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { baseURL } from "../constants/Config";

const ShowReel = () => {
  const [videoPage, setVideoPage] = useState();
  const [muted, setMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const location = useLocation();

  // const audio1 = new Audio(animation1);

  useEffect(() => {
    request({
      method: METHODS.GET,
      url: `/api/video/${location.state.id}/`,
    }).then((videores) => {
      setVideoPage(videores.data.data);
      setIsLoading(false);
    });
  }, [location?.state?.id]);

  const videoRef = useRef();

  const MutedOnClick = () => {
    setMuted((prev) => !prev);
  };

  const handleProgress = (e) => {
    if (isNaN(e.target.duration)) return;
    setProgress((e.target.currentTime / e.target.duration) * 100);
  };

  const handle = useFullScreenHandle();

  const handleOnChange = (e) => {
    if (e === false) {
      setIsFullScreen(false);
    }
  };

  // const hoverEvent = () => {
  //   audio1.play();
  // };

  return (
    <div>
      <Stack sx={{ color: "primary" }}>
        {isLoading ? (
          <div className="home-loader-container">
            <div>
              <h4 style={{ color: "#fff" }}>FULLBLACK</h4>
              <LinearProgress className="loader-homepage" />
            </div>
          </div>
        ) : (
          <FullScreen handle={handle} onChange={handleOnChange}>
            <div className="home-layout">
              <NavBar>
                {!isFullScreen && (
                  <div className="video-top-text">
                    <NavHeadding style={{ margin: 0 }}>
                      {location?.state?.title?.toUpperCase()}
                    </NavHeadding>
                    <HeadingSubTitle>
                      {location?.state?.subtitle?.toUpperCase()}
                    </HeadingSubTitle>
                  </div>
                )}
                {!isFullScreen && (
                  <div className="aaa">
                    <NavLink to="/" className="linkEffect">
                      EXIT
                    </NavLink>
                  </div>
                )}
              </NavBar>
              {videoPage?.video && (
                <StyledVideo
                  playsInline
                  muted={muted}
                  autoPlay
                  loop
                  controls
                  duration
                  onProgress={handleProgress}
                  width={window.screen.availWidth}
                  height={window.innerHeight}
                  ref={videoRef}
                >
                  <source
                    src={`${baseURL}media/${videoPage?.video}`}
                    type="video/mp4"
                  />
                </StyledVideo>
              )}

              {/* <div className="footer">
                <Box sx={{ width: "290px" }} className="footer-center">
                  <LinearProgress
                    variant="determinate"
                    id="progress"
                    max="100"
                    value={progress}
                    width="50%"
                  />
                </Box>

                <div>
                  {!isFullScreen && (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        setIsFullScreen(true);
                        handle.enter();
                      }}
                    >
                      <Fullscreen />
                    </IconButton>
                  )}
                  <Button variant="text" onClick={MutedOnClick}>
                    SOUND {muted ? "ON" : "OFF"}
                  </Button>
                </div>
              </div> */}
            </div>
          </FullScreen>
        )}
      </Stack>
    </div>
  );
};

export default ShowReel;

const NavBar = styled.div`
  display: flex;
  position: absolute;
  z-index: 999;
  color: #fff;
  top: 5%;
  justify-content: space-between;
  width: 93%;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

const NavLink = styled(Link)`
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  position: relative;
`;

const NavHeadding = styled.h3`
  font-size: 20px;
  text-decoration: none;
  color: #fff;
`;
const StyledVideo = styled.video`
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
`;
const HeadingSubTitle = styled(NavHeadding)`
  color: #ffb400;
  font-size: 10px;
  margin: 0;
`;
// const FooterLink = styled(Link)`
//   font-size: 14px;
//   display: inline-block;
//   text-decoration: none;
//   color: #fff;
//   position: relative;
// `;
