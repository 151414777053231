import React from "react";
import AboutUs from "../AboutUs";
import ContactUs from "../ContactUs";
import Home from "../Home";
import LandingPage from "../LandingPage";
import LandingVideoPage from "../LandingVideoPage";
import ShowReel from "../ShowReel";
import VideoList from "../VideoList";

const NavigationProvider = [
  {
    path: "/",
    component: <Home />,
    exact: true,
  },
  {
    path: "/contactus",
    component: <ContactUs />,
    exact: true,
  },
  {
    path: "/landingpage",
    component: <LandingPage />,
    exact: true,
  },
  {
    path: "/landingvideopage",
    component: <LandingVideoPage />,
    exact: true,
  },
  {
    path: "/aboutus",
    component: <AboutUs />,
    exact: true,
  },
  {
    path: "/videolist",
    component: <VideoList />,
    exact: true,
  },
  {
    path: "/showreel/:name",
    component: <ShowReel />,
    exact: true,
  },
];

export default NavigationProvider;
